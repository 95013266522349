import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../_common/services/user/user.service';
import { AuthnService } from '../../_common/services/authn/authn.service';
import { BreadcrumbsService } from '../../_common/services/breadcrumbs/breadcrumbs.service';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { LocalizedTextIds, UserRoles } from 'company-finder-common';
import { KeyValue } from '@angular/common';
import { getParsedReturnUrl } from '../../_common/utilities/navigation/navigation.utils';
import { ComponentBase } from '../../_common/components/_component.base';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { SearchResolverResult } from '../../_common/resolvers/search-results-resolver';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends ComponentBase implements OnInit {
  public username: string;
  public password: string;
  public authenticationMessage: string;
  public userRole: KeyValue<string, string>;
  public company: KeyValue<string, string>;

  public userRoles = UserRoles;
  public companies: KeyValue<string, string>[] = [];
  public isDebug = false;

  constructor(
    dc: DeploymentContext,
    private _authnService: AuthnService,
    private _breadcrumbsService: BreadcrumbsService,
    private _router: Router,
    private _webAnalyticsService: WebAnalyticsService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private _activatedRoute: ActivatedRoute
  ) {
    super(dc);
    this.isDebug = !!activatedRoute.snapshot.url.find(
      (segment) => segment.path.toLocaleLowerCase() === 'debug'
    );
    this.userRole = {
      key: UserRoles.Company,
      value: UserRoles.Company.toString(),
    };
  }

  ngOnInit(): void {
    this._breadcrumbsService.clearBreadcrumbs();
    this._authnService.clearJnjLoggedOut();
    if (this.isDebug) {
      this.addSubscription(
        this._activatedRoute.data.subscribe(
          async (data: { searchResults: SearchResolverResult }) => {
            this.companies = data.searchResults.companies
              .map((company, idx) => ({
                key: idx.toString(),
                value: company.name,
              }))
              .sort((a, b) => a.value.localeCompare(b.value));
          }
        )
      );
    }
  }

  public async login(): Promise<void> {
    this.clearTokenAndUser();
    this.authenticationMessage = '';
    let username = this.username?.trim();

    await this._authnService.login(username, this.password, this.userRole?.value, this.company?.value);

    if (this._authnService.isAuthenticated) {
      this._webAnalyticsService.trackEvent('login', {
        category: 'successful-login',
        label: this.username,
      });

      const target = getParsedReturnUrl(this.activatedRoute);
      this._router.navigateByUrl(target);
    } else {
      this.authenticationMessage = this.Loc(LocalizedTextIds.LoginJJNetwork);
    }
  }

  public getReferenceValueDisplayString(
    role: KeyValue<string, string>
  ): string {
    return role?.value ?? '';
  }

  public logout(): void {
    this.clearTokenAndUser();
    this._router.navigate(['/']);
  }

  private clearTokenAndUser(): void {
    this._authnService.clearToken();
    this.userService.clearCachedUser();
  }
}
